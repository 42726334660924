import React from 'react';
import classNames from 'classnames';
import { IGatsbyConnection } from 'gatsby/dist/gatsby/src/schema/type-definitions';
import { IGlobal, ILocale, IPage } from 'interfaces';
import { Link } from 'components/Link';
import { Image } from 'components/Image';
import { HtmlMarkdownContent } from 'components/HtmlMarkdownContent';
import { FooterColumns } from 'enums/FooterColumns';
import { SocialLink } from './SocialLink';
import classes from './Footer.module.scss';

interface IProps {
    global: IGlobal;
    pages: IGatsbyConnection<IPage>;
    locale: ILocale;
}

export const Footer: React.FC<IProps> = (props) => (
    <footer>
        <div className={classes.footerNavBlock}>
            <div className={classes.container}>
                <nav className={classes.footerCellFlex}>
                    <div className={classes.navList}>
                        {props.global.footerContent.footerNavigation.map(({ id, url, newTab, text, column }) => {
                            if (column === FooterColumns.FIRST) {
                                return (
                                    <div key={id} className={classes.linkContainer}>
                                        <Link
                                            url={url}
                                            className={classNames(classes.link)}
                                            newTab={newTab}
                                            isFooter
                                            locale={props.global.locale}
                                        >
                                            {text}
                                        </Link>
                                    </div>
                                );
                            }
                        })}
                    </div>
                    <div className={classes.navList}>
                        {props.global.footerContent.footerNavigation.map(({ id, url, newTab, text, column }) => {
                            if (column === FooterColumns.SECOND) {
                                return (
                                    <div key={id} className={classes.linkContainer}>
                                        <Link
                                            url={url}
                                            className={classNames(classes.link)}
                                            newTab={newTab}
                                            isFooter
                                            locale={props.global.locale}
                                        >
                                            {text}
                                        </Link>
                                    </div>
                                );
                            }
                        })}
                    </div>
                    <div className={classes.navList} />
                </nav>
                <div className={classes.footerCell}>
                    <Link className={classes.logo} url={`/${props.locale.code}`}>
                        <Image image={props.global.logo} alt="logo" className={classes.logoImage} />
                    </Link>
                    <If condition={props.global.footerContent.socialLinks?.length}>
                        <div className={classes.socialLinksBlock}>
                            <div className={classes.title}>{props.global.footerContent.socialTitle}</div>
                            <ul className={classes.links}>
                                {props.global.footerContent.socialLinks.map((link) => (
                                    <li key={link.id}>
                                        <SocialLink link={link} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </If>
                </div>

                <div className={classes.footerCell}>
                    <If condition={props.global.footerContent.licensesLinks.length}>
                        <ul className={classes.licensesLinksList}>
                            {props.global.footerContent.licensesLinks.map(({ id, url, icon, text }) => (
                                <li key={id}>
                                    <Link url={url} newTab className={classNames(classes.link)}>
                                        <Image image={icon} alt={text} className={classes.image} />
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </If>
                </div>
            </div>
        </div>
        {!!props.global.footerContent.footerRichText && (
            <div className={classes.footerRichText}>
                <HtmlMarkdownContent content={props.global.footerContent.footerRichText} withSpacing={false} />
            </div>
        )}
        {!!props.global.footerContent.copyright && (
            <div className={classes.footerBottom}>
                <nav className={classes.footerLinks}>
                    {props.global.footerContent.footerBottom.map(({ url, newTab, text }) => (
                        <div>
                            <Link
                                url={url}
                                className={classes.linkWhite}
                                newTab={newTab}
                                isFooter
                                locale={props.global.locale}
                            >
                                {text}
                            </Link>
                        </div>
                    ))}
                </nav>
                <div className={classes.footerCellBottom}>
                    <div className={classes.copyright}>{props.global.footerContent.copyright}</div>
                </div>

                <div className={classes.footerCellBottom} />
            </div>
        )}
    </footer>
);
