import React from 'react';
import { IIcon } from '../interfaces';
import { Icon } from '../Icon';
import { IconType } from '../enums';

export const BurgerIcon: React.FC<IIcon> = (props) => (
    <Icon className={props.className} icon={IconType.BURGER}>
        <svg width="20" height="20" viewBox="-1 -4 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" fill="white" />
        </svg>
    </Icon>
);
