import React from 'react';
import { Helmet, HelmetProps } from 'react-helmet';
import { IGlobal, ISeo } from 'interfaces';
import { getImageUrl } from 'utils/getImageUrl';

interface IProps {
    global: IGlobal;
    title?: string;
    description?: string;
    image?: string;
    seo?: ISeo;
}

const defaultProps: Partial<IProps> = {
    seo: {} as ISeo,
};
const isBrowser = typeof window !== 'undefined';

export const Seo: React.FC<IProps> = (props) => {
    const { defaultSeo, siteName, logo, favicon } = props.global;

    // Merge default and page-specific SEO values
    const fullSeo = { ...defaultSeo, ...props.seo };

    const getMetaTags = (): HelmetProps['meta'] => {
        const tags = [];

        if (fullSeo.metaTitle) {
            tags.push(
                {
                    property: 'og:site_name',
                    content: siteName,
                },
                {
                    property: 'og:title',
                    content: fullSeo.metaTitle,
                },
                {
                    property: 'og:url',
                    content: isBrowser ? window.location.href : null,
                },
                {
                    property: 'twitter:title',
                    content: fullSeo.metaTitle,
                },
            );
        }

        if (fullSeo.metaDescription) {
            tags.push(
                {
                    name: 'description',
                    content: fullSeo.metaDescription,
                },
                {
                    property: 'og:description',
                    content: fullSeo.metaDescription,
                },
                {
                    property: 'twitter:description',
                    content: fullSeo.metaDescription,
                },
            );
        }

        if (fullSeo.metaImage) {
            tags.push(
                {
                    property: 'image',
                    content: fullSeo.metaImage.url,
                },
                {
                    property: 'og:image',
                    content: fullSeo.metaImage.url,
                },
            );
        } else {
            tags.push(
                {
                    property: 'image',
                    content: defaultSeo?.metaImage?.url,
                },
                {
                    property: 'og:image',
                    content: defaultSeo?.metaImage?.url,
                },
            );
        }

        if (logo.url) {
            tags.push({
                property: 'twitter:image',
                content: logo.url,
            });
        }

        return tags;
    };

    const metaTags = getMetaTags();

    return (
        <Helmet
            title={fullSeo.metaTitle}
            titleTemplate={`%s | ${siteName}`}
            htmlAttributes={{
                lang: props.global.locale || 'en',
            }}
            link={[
                {
                    rel: 'icon',
                    href: getImageUrl(favicon.url),
                },
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap',
                },
            ]}
            meta={metaTags}
        >
            <script type="text/javascript">
                {
                    // eslint-disable-next-line quotes
                    "(function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; window.ldfdr('cfg','enableAutoTracking',false,ss); (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('DzLR5a5Jvr08BoQ2');"
                }
            </script>
        </Helmet>
    );
};

Seo.defaultProps = defaultProps;
