export enum IconType {
    CLOSE = 'close',
    BURGER = 'burger',
    CHECK = 'check',
    LINK = 'link',
    FACEBOOK = 'facebook',
    INSTAGRAM = 'instagram',
    TWITTER = 'twitter',
    LINKEDIN = 'linkedin',
    YOUTUBE = 'youtube',
    VIMEO = 'vimeo',
    SKYPE = 'skype',
}
