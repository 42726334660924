import React, { useMemo, FC } from 'react';

import { IGatsbyConnection } from 'gatsby/dist/gatsby/src/schema/type-definitions';
import { ILocale, ILocales, ILocalization, IPageWithLocalizations, IPublicLocales } from 'interfaces';
import { Link } from 'components/Link';
import flagRU from 'images/flagRU.svg';
import flagGB from 'images/flagGB.svg';
import flagLT from 'images/flagLT.svg';
import { useLocation } from '@reach/router';

import classes from './LanguageSelect.module.scss';

interface IProps {
    locale: ILocale;
    locales?: ILocales;
    localization: IPageWithLocalizations | null;
    pagesWithLocalization: IGatsbyConnection<ILocalization> | null;
    publicLocales: IGatsbyConnection<IPublicLocales>;
    parentPageLocalizations: IGatsbyConnection<ILocalization> | null;
}
interface ILanguages {
    locale: string;
    name: string;
    img: string;
    url: string;
}
const languages = [
    { locale: 'lt', name: 'LT', img: flagLT, url: '/lt/404' },
    { locale: 'ru', name: 'RU', img: flagRU, url: '/ru/404' },
    { locale: 'en', name: 'EN', img: flagGB, url: '/en/404' },
];

const getParentSlug = (
    parentLocalizations: ILocalization[] | undefined,
    currentPageLocale: string,
    parentPageLocalizations: IGatsbyConnection<ILocalization> | null,
): string => {
    const daId = parentLocalizations?.find(({ locale: parentLocale }) => parentLocale === currentPageLocale)?.id;
    const parentSlug = parentPageLocalizations?.nodes.find(
        ({ strapiId }) => strapiId.toString() === daId?.toString(),
    )?.slug;
    return parentSlug ? `${parentSlug}/` : '';
};

export const LanguageSelect: FC<IProps> = ({
    locale,
    locales,
    localization,
    pagesWithLocalization,
    publicLocales,
    parentPageLocalizations,
}) => {
    const location = useLocation();
    const parentPageSlug = location.pathname
        .replace(`/${localization?.slug}`, '')
        .replace(`/${locale.code}/`, '')
        .replace('/', '');

    const parentLocalizations = parentPageLocalizations?.nodes.find(
        ({ slug }) => slug === parentPageSlug,
    )?.localizations;

    const currentLanguage = languages.find(({ locale: _locale }) => _locale === locale.code);

    const langToUse = languages.filter(
        ({ locale: _locale }) =>
            _locale !== locale.code &&
            publicLocales?.nodes.find(
                ({ locale: publicLocale, publishLocale }) => publicLocale === _locale && publishLocale,
            ) &&
            locales?.edges.find(({ node }) => node.code === _locale),
    );

    const getUrlForLocalizations = (
        localizations: IPageWithLocalizations | null,
        pages: IGatsbyConnection<ILocalization> | null,
        langs: ILanguages[],
    ): ILanguages[] =>
        langs.map((langObject) => {
            const localizedPageId = localizations?.localizations.find(
                ({ locale: _locale }) => _locale === langObject.locale,
            )?.id;
            if (localizedPageId) {
                const url = `/${langObject.locale}/${getParentSlug(
                    parentLocalizations,
                    langObject.locale,
                    parentPageLocalizations,
                )}${
                    pages?.nodes.find(({ strapiId }) => localizedPageId.toString() === strapiId?.toString())?.slug || ''
                }`;
                return { ...langObject, url };
            }
            return { ...langObject };
        });

    const availableLanguages = useMemo(
        () => getUrlForLocalizations(localization, pagesWithLocalization, langToUse),
        [localization, pagesWithLocalization],
    );

    return (
        <div className={classes.languageSelect}>
            <div className={classes.languageSelect__selected}>
                <span className={classes.languageSelect__selected__text}>{currentLanguage?.name}</span>
                <img src={currentLanguage?.img} alt="flag" />
            </div>
            {!!availableLanguages.length && (
                <div className={classes.languageSelect__dropdown}>
                    {availableLanguages.map(({ name, url, img }) => (
                        <Link key={name} className={classes.languageSelect__dropdown__link} url={url}>
                            <span className={classes.languageSelect__dropdown__link__text}>{name}</span>
                            <img src={img} alt="flag" />
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};
