import React from 'react';
import { IIcon } from '../interfaces';
import { Icon } from '../Icon';
import { IconType } from '../enums';

export const CheckIcon: React.FC<IIcon> = (props) => (
    <Icon className={props.className} icon={IconType.CHECK}>
        <svg width="20" height="20" viewBox="0 -2 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00016 11.17L1.83016 7L0.410156 8.41L6.00016 14L18.0002 2L16.5902 0.589996L6.00016 11.17Z" />
        </svg>
    </Icon>
);
