import React, { useState } from 'react';
import classNames from 'classnames';
import { IGatsbyConnection, IGatsbyEdge } from 'gatsby/dist/gatsby/src/schema/type-definitions';
import { LinkType } from 'enums/LinkType';
import {
    IGlobal,
    ILocale,
    ILocales,
    IPage,
    ILocalization,
    IPageWithLocalizations,
    IPublicLocales,
    ILink,
    IParent,
} from 'interfaces';
import { CloseIcon, BurgerIcon } from 'components/Icon';
import { Link } from 'components/Link';
import { Image } from 'components/Image';
import { useLocation } from '@reach/router';
import { ButtonType } from 'src/enums/ButtonType';
import classes from './Header.module.scss';
import { NavigationLink } from './NavigationLink';
import { LanguageSelect } from './LanguageSelect';
import { NavigationDropdown } from './NavigationDropdown';

interface IProps {
    global: IGlobal;
    pages: IGatsbyConnection<IPage>;
    parents: IGatsbyConnection<IParent>;
    locale: ILocale;
    locales?: ILocales;
    localization: IPageWithLocalizations | null;
    pagesWithLocalization: IGatsbyConnection<ILocalization> | null;
    publicLocales: IGatsbyConnection<IPublicLocales>;
    parentPageLocalizations: IGatsbyConnection<ILocalization> | null;
}

export const Header: React.FC<IProps> = (props) => {
    const [isOpened, updateOpened] = useState(false);
    const onNavLinkClick = React.useCallback(() => {
        updateOpened(false);
    }, []);
    const location = useLocation();
    const parentPageSlug = location.pathname
        .replace(`/${props?.localization?.slug}`, '')
        .replace(`/${props.locale.code}/`, '')
        .replace('/', '');

    const joinedNavigationLinks = [...props.pages.edges] as (IGatsbyEdge<IPage> | IGatsbyEdge<IParent>)[];
    if (props.parents?.edges) {
        joinedNavigationLinks.push(...props.parents.edges);
    }

    joinedNavigationLinks.sort((firstNode, secondNode) => {
        const first = firstNode.node.sort || 0;
        const second = secondNode.node.sort || 0;
        return first < second ? -1 : 1;
    });

    const mainLink: ILink | undefined = props.global.navigationLinks?.find((link) => !!link.mainLink);
    return (
        <header className={classes.container}>
            <div className={classes.header}>
                <Link className={classes.logo} url={`/${props.locale.code}`}>
                    <Image image={props.global.logo} alt="logo" className={classes.logoImage} />
                </Link>
                <nav className={classes.navigation}>
                    {!!mainLink && (
                        <NavigationLink
                            url={mainLink.url}
                            text={mainLink.text}
                            type={mainLink.type as ButtonType}
                            newTab
                            className={classes.mainLinkHeader}
                        />
                    )}
                    <button
                        className={classNames(classes.toggleButton, { [classes.opened]: isOpened })}
                        onClick={() => updateOpened(!isOpened)}
                        type="button"
                    >
                        <Choose>
                            <When condition={isOpened}>
                                <CloseIcon className={classes.icon} />
                            </When>
                            <Otherwise>
                                <BurgerIcon className={classes.icon} />
                            </Otherwise>
                        </Choose>
                    </button>
                    <ul className={classNames(classes.navigationLinksContainer, { [classes.opened]: isOpened })}>
                        {joinedNavigationLinks.map(({ node }) => {
                            if (node?.pages?.length) {
                                return (
                                    <li key={node.slug} className={classes.listItem}>
                                        <NavigationDropdown subPages={node.pages} name={node.name} />
                                    </li>
                                );
                            }
                            return (
                                <li key={node.slug} className={classes.listItem}>
                                    <NavigationLink
                                        isInnerPage={parentPageSlug === node.slug}
                                        url={`/${props.locale.code}/${node.slug}`}
                                        text={node.title}
                                        type={LinkType.LINK}
                                        onClick={onNavLinkClick}
                                    />
                                </li>
                            );
                        })}
                        {props.global.navigationLinks?.map((navLink) => (
                            <li key={navLink.id} className={classes.listItem}>
                                <NavigationLink
                                    url={navLink.url}
                                    text={navLink.text}
                                    type={navLink.type}
                                    newTab={navLink.newTab}
                                    onClick={onNavLinkClick}
                                    className={classNames({ [classes.mainLinkDropdown]: !!navLink.mainLink })}
                                />
                            </li>
                        ))}
                    </ul>
                    <LanguageSelect
                        locale={props.locale}
                        locales={props.locales}
                        localization={props.localization}
                        pagesWithLocalization={props.pagesWithLocalization}
                        publicLocales={props.publicLocales}
                        parentPageLocalizations={props.parentPageLocalizations}
                    />
                </nav>
            </div>
        </header>
    );
};
