import React from 'react';
import { IIcon } from '../interfaces';
import { Icon } from '../Icon';
import { IconType } from '../enums';

export const TwitterIcon: React.FC<IIcon> = (props) => (
    <Icon className={props.className} icon={IconType.TWITTER}>
        <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
            <path
                fill="white"
                d="M59.7,142.33c-19.86-1-31.85-14.7-35.12-26.23a39.66,39.66,0,0,0,16.26-.63c-18.69-5.73-28.56-18-29.91-37.65,5.47,2.6,10.71,4.53,17,4.58C18,75,12.47,65.52,11.54,53.63,11,46,13.24,36.82,16.66,32.29,37,56.23,62.85,69.4,94.31,71.61c-.25-2.75-.62-5.27-.67-7.79a37.75,37.75,0,0,1,64.18-27.9,2.68,2.68,0,0,0,2.86.81A79.44,79.44,0,0,0,183,27.92a39.79,39.79,0,0,1-15.58,20.49c7.05-.57,13.68-2.64,20.56-5.45A75.72,75.72,0,0,1,174.4,58.34c-1.68,1.45-4,2.58-4.89,4.41s-.16,4.27-.19,6.44c-.63,40.87-25.42,79.41-61.95,96.16C90.13,173.26,72,176,53.11,174.54A105.78,105.78,0,0,1,5.6,159.08c-.37-.22-.72-.48-1.58-1.06C24.76,159.89,43,154.9,59.7,142.33Z"
            />
        </svg>
    </Icon>
);
