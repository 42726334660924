import React from 'react';
import classNames from 'classnames';
import { ILink } from 'interfaces';
import { LinkType } from 'enums/LinkType';
import { ButtonType } from 'enums/ButtonType';
import { Button } from 'components/Button';
import { Link } from 'components/Link';
import classes from './NavigationLink.module.scss';

interface IProps extends ILink {
    className?: string;
    onClick?(): void;
    isInnerPage?: boolean;
}

export const NavigationLink: React.FC<IProps> = (props) => {
    const isLinkType = props.type === LinkType.LINK;
    const Component = isLinkType ? Link : Button;

    return (
        <Component
            url={props.url}
            newTab={props.newTab}
            type={props.type as ButtonType}
            className={classNames(props.className, {
                [classes.linkType]: isLinkType,
                [classes.buttonType]: !isLinkType,
                [classes.parentOfInnerPage]: props.isInnerPage,
            })}
            activeClassName={classes.active}
            title={props.text}
            onClick={props.onClick}
        >
            {props.text}
        </Component>
    );
};
