import React from 'react';
import { IIcon } from '../interfaces';
import { Icon } from '../Icon';
import { IconType } from '../enums';

export const InstagramIcon: React.FC<IIcon> = (props) => (
    <Icon className={props.className} icon={IconType.INSTAGRAM}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
            <path
                fill="white"
                d="M179.45,61.38c-.4-8.93-1.82-15-3.9-20.38A42.89,42.89,0,0,0,151,16.45c-5.34-2.07-11.45-3.49-20.38-3.9S118.8,12,96,12s-25.66.1-34.61.51-15,1.83-20.38,3.9a41.16,41.16,0,0,0-14.87,9.68A41.22,41.22,0,0,0,16.45,41c-2.07,5.35-3.49,11.45-3.9,20.38S12,73.2,12,96s.1,25.66.51,34.62,1.83,15,3.9,20.37a41.06,41.06,0,0,0,9.69,14.87A41.06,41.06,0,0,0,41,175.55c5.34,2.07,11.44,3.49,20.38,3.9S73.2,180,96,180s25.66-.1,34.62-.51,15-1.83,20.38-3.9A42.95,42.95,0,0,0,175.55,151c2.08-5.34,3.5-11.44,3.9-20.37S180,118.8,180,96,179.86,70.34,179.45,61.38ZM96,139.11A43.11,43.11,0,1,1,139.11,96,43.11,43.11,0,0,1,96,139.11Zm44.82-77.85a10.08,10.08,0,1,1,10.07-10.08A10.08,10.08,0,0,1,140.82,61.26Z"
            />
            <path fill="white" d="M124,96a28,28,0,1,0-28,28A28,28,0,0,0,124,96Z" />
        </svg>
    </Icon>
);
