import React from 'react';
import { IIcon } from '../interfaces';
import { Icon } from '../Icon';
import { IconType } from '../enums';

export const FacebookIcon: React.FC<IIcon> = (props) => (
    <Icon className={props.className} icon={IconType.FACEBOOK}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
            <path
                fill="white"
                d="M110.39,48.72c-.18,7.16,0,14.34,0,21.5a6.54,6.54,0,0,0,.24,1.06h32.34c-1.42,11.06-2.81,21.86-4.26,33H110.45v84H76.19V104.07H48.32V71.25H76.37c0-5.38-.11-10.49,0-15.61.18-6.25,0-12.57,1-18.71,2-13.1,8.63-23.19,20.89-29a42.71,42.71,0,0,1,16.14-4c9.8-.54,19.55.22,29.31,1.08V34.4H141c-5.43,0-10.87-.09-16.3.05C115.15,34.66,110.63,39.12,110.39,48.72Z"
            />
        </svg>
    </Icon>
);
