import React from 'react';
import classNames from 'classnames';
import { IconType } from './enums';
import { IIcon } from './interfaces';
import classes from './Icon.module.scss';

export interface IProps extends IIcon {
    icon: IconType;
}

export const Icon: React.FC<IProps> = (props) => (
    <i className={classNames(classes.icon, classes[props.icon], props.className)}>{props.children}</i>
);
