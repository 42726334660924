import React from 'react';
import { IIcon } from '../interfaces';
import { Icon } from '../Icon';
import { IconType } from '../enums';

export const LinkIcon: React.FC<IIcon> = (props) => (
    <Icon className={props.className} icon={IconType.LINK}>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.8"
                d="M16.2812 8.71875C16 8.4375 15.6719 8.15625 15.3438 7.92188C15.0156 7.6875 14.6406 7.73438 14.3594 7.96875L13.375 8.95312C13 9.375 12.8125 9.89062 12.8125 10.3594C12.8125 10.6406 12.9531 10.8281 13.1406 10.9688C13.2812 11.0625 13.4688 11.2031 13.6562 11.3438C14.9688 12.7031 14.9688 14.8125 13.6562 16.125L10.4688 19.2656C9.15625 20.625 7 20.625 5.6875 19.2656C4.375 17.9531 4.42188 15.7969 5.73438 14.4844L6.34375 13.875C6.57812 13.6875 6.625 13.3594 6.53125 13.0781C6.25 12.2812 6.10938 11.4375 6.0625 10.6406C6.0625 9.98438 5.26562 9.65625 4.79688 10.125L3.0625 11.8594C0.296875 14.625 0.296875 19.1719 3.0625 21.9375C5.82812 24.7031 10.375 24.7031 13.1406 21.9375L16.2812 18.7969C16.2812 18.7969 16.2812 18.7969 16.2812 18.75C19.0469 16.0312 19.0938 11.5312 16.2812 8.71875ZM22.8906 2.10938C20.125 -0.65625 15.5781 -0.65625 12.8125 2.10938L9.67188 5.25C9.67188 5.25 9.67188 5.25 9.67188 5.29688C6.90625 8.01562 6.85938 12.5156 9.67188 15.3281C9.95312 15.6094 10.2812 15.8906 10.6094 16.125C10.9375 16.3594 11.3125 16.3125 11.5938 16.0781L12.5781 15.0938C12.9531 14.6719 13.1406 14.1562 13.1406 13.6875C13.1406 13.4062 13 13.2188 12.8125 13.0781C12.6719 12.9844 12.4844 12.8438 12.2969 12.7031C10.9844 11.3438 10.9844 9.23438 12.2969 7.92188L15.4844 4.78125C16.7969 3.42188 18.9531 3.42188 20.2656 4.78125C21.5781 6.09375 21.5312 8.25 20.2188 9.5625L19.6094 10.1719C19.375 10.3594 19.3281 10.6875 19.4219 10.9688C19.7031 11.7656 19.8438 12.6094 19.8906 13.4062C19.8906 14.0625 20.6875 14.3906 21.1562 13.9219L22.8906 12.1875C25.6562 9.42188 25.6562 4.875 22.8906 2.10938Z"
                fill="white"
            />
        </svg>
    </Icon>
);
