import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { ButtonType } from 'enums/ButtonType';
import { ILink } from 'interfaces';
import { useLocation } from '@reach/router';
import { LOCALE_REGEX, URL_REGEX } from 'src/constants/regexp';
import { EN_LOCALE } from './constants';
import classes from './Button.module.scss';

type IButtonType = 'button' | 'submit';

interface IProps extends Partial<ILink> {
    disabled?: boolean;
    onClick?(): void;
    className?: string;
    activeClassName?: string;
    buttonType?: IButtonType;
}

const defaultProps: Partial<IProps> = {
    type: ButtonType.PRIMARY,
};

export const Button: React.FC<IProps> = (props) => {
    const location = useLocation();
    const className = classNames(classes.button, [classes[props.type as string]], props.className, {
        [classes.disabled]: props.disabled,
    });
    const locale = LOCALE_REGEX.exec(location.pathname)?.[0] || EN_LOCALE;
    if (!props.url) {
        return (
            <button
                type={props.buttonType === 'submit' ? 'submit' : 'button'}
                disabled={props.disabled}
                onClick={props.onClick}
                className={className}
            >
                {props.children}
            </button>
        );
    }

    // is full url
    if (URL_REGEX.test(props.url)) {
        return (
            <a
                rel="noreferrer"
                href={props.url}
                onClick={(e) => {
                    if (props.disabled) {
                        e.preventDefault();
                    }
                }}
                target={props.newTab ? '_blank' : '_self'}
                className={className}
            >
                {props.children}
            </a>
        );
    }

    return (
        <Link
            to={`${locale}/${props.url}`}
            onClick={(e) => {
                if (props.disabled) {
                    e.preventDefault();
                }
            }}
            className={className}
            activeClassName={props.activeClassName}
        >
            {props.children}
        </Link>
    );
};

Button.defaultProps = defaultProps;
