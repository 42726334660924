import React from 'react';
import { Link as InternalLink } from 'gatsby';
import { URL_REGEX } from 'src/constants/regexp';

interface IProps {
    url?: string;
    title?: string;
    newTab?: boolean;
    className?: string;
    activeClassName?: string;
    isFooter?: boolean;
    locale?: string;
    onClick?(): void;
}

export const Link: React.FC<IProps> = (props) => {
    if (props.newTab) {
        return (
            <a
                href={
                    props.isFooter
                        ? URL_REGEX.test(props.url)
                            ? props.url
                            : `/${props.locale}/${props.url}`
                        : props.url
                }
                target="_blank"
                rel="noreferrer"
                className={props.className}
                onClick={props.onClick}
                title={props.title}
            >
                {props.children}
            </a>
        );
    }

    return (
        <InternalLink
            to={props.isFooter ? `/${props.locale}/${props.url}` : props.url}
            title={props.title}
            className={props.className}
            activeClassName={props.activeClassName}
            onClick={props.onClick}
        >
            {props.children as JSX.Element}
        </InternalLink>
    );
};

Link.defaultProps = {
    newTab: false,
};
