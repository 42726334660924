import React, { FC } from 'react';

import { ILink } from 'interfaces';
import { LinkType } from 'src/enums/LinkType';

import classes from './NavigationDropdown.module.scss';
import { NavigationLink } from '../NavigationLink';

interface IProps extends ILink {
    subPages?: {
        slug: string;
        locale: string;
        title: string;
    }[];
    name?: string;
}

export const NavigationDropdown: FC<IProps> = ({ subPages, name }) => (
    <div className={classes.subPages}>
        {name && <div className={classes.subPages__heading}>{name}</div>}
        {!!subPages?.length && (
            <div className={classes.subPages__dropdown}>
                {subPages.map(({ title, slug, locale }) => (
                    <NavigationLink
                        key={title}
                        className={classes.subPages__dropdown__link}
                        url={`/${locale}/${slug}`}
                        type={LinkType.LINK}
                        text={title}
                    />
                ))}
            </div>
        )}
    </div>
);
