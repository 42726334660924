import React from 'react';
import { IImage } from 'interfaces';
import { getImageUrl } from 'utils/getImageUrl';

interface IProps {
    image: IImage;
    alt?: string;
    className?: string;
}

const defaultProps: Partial<IProps> = {
    alt: '',
};

export const Image: React.FC<IProps> = (props) => {
    if (!props.image) {
        return null;
    }

    return <img src={getImageUrl(props.image.url)} alt={props.alt} className={props.className} />;
};

Image.defaultProps = defaultProps;
