import React from 'react';
import { IIcon } from '../interfaces';
import { Icon } from '../Icon';
import { IconType } from '../enums';

export const LinkedinIcon: React.FC<IIcon> = (props) => (
    <Icon className={props.className} icon={IconType.LINKEDIN}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
            <path
                fill="white"
                d="M106.2,177.16H70V67.78H106.2V82.12c2.07-1.79,4.43-3.8,6.76-5.85,6.37-5.59,13.41-9.93,22-10.83,17.6-1.86,35.93,5.7,42.89,27.42a70.17,70.17,0,0,1,3.08,21.43q.06,30.61,0,61.21c0,.52-.06,1-.1,1.64H144.53V175c0-18.51.09-37-.06-55.54A50.83,50.83,0,0,0,143,107.22c-3.41-13.27-18.82-17.93-29.29-9a20,20,0,0,0-7.5,15.75c0,9.08,0,18.16,0,27.24q0,16.89,0,33.79Z"
            />
            <path fill="white" d="M13.34,67.75H49.55V177.08H13.34Z" />
            <path
                fill="white"
                d="M52,33.86A18.49,18.49,0,0,1,35.65,52.38c-6.56.92-12.77.13-18-4.26-5.95-5-7.7-11.6-6-19S18.42,17.47,25.6,15.61a24.32,24.32,0,0,1,12.59.12A18.36,18.36,0,0,1,52,33.86Z"
            />
        </svg>
    </Icon>
);
