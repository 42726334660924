import React from 'react';
import { IIcon } from '../interfaces';
import { Icon } from '../Icon';
import { IconType } from '../enums';

export const VimeoIcon: React.FC<IIcon> = (props) => (
    <Icon className={props.className} icon={IconType.VIMEO}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
            <path
                fill="white"
                d="M14.9,63c3.25-2.92,6.53-5.81,9.76-8.75,7.85-7.14,15.49-14.53,24.23-20.6a52.22,52.22,0,0,1,9.74-5.49,16.9,16.9,0,0,1,20.1,5.64c4,5.17,5.67,11.26,6.77,17.53,2.28,12.93,4.34,25.89,6.74,38.79A79.12,79.12,0,0,0,98,109a24.46,24.46,0,0,0,3.15,5.08c1.82,2.24,3.89,2.62,6.09.78a44.74,44.74,0,0,0,7-7.23A109.48,109.48,0,0,0,128.45,83.1a25.87,25.87,0,0,0,2.2-13c-.73-6.11-4-9-10.18-9.27a28.35,28.35,0,0,0-11.14,2l-1.57.57c.46-1.42.81-2.61,1.22-3.78,2.7-7.67,6.22-14.89,11.61-21.07,7.6-8.73,17.29-13.12,28.77-13.47a44.2,44.2,0,0,1,10.22.74c9.15,1.89,14.15,8.07,16.2,16.85,2.63,11.26,1.18,22.2-2.95,32.88-4.3,11.14-10.29,21.36-17,31.19-9.42,13.86-19.51,27.19-31.29,39.15-6.92,7-14.29,13.52-23.36,17.64a46.62,46.62,0,0,1-9.61,3c-7.1,1.49-12.66-1.4-17.21-6.67a50.42,50.42,0,0,1-9.65-17.32c-2.68-8.14-4.77-16.49-7.08-24.75C54.28,105.74,51,93.62,47.48,81.6A63.7,63.7,0,0,0,43,71.67a14.6,14.6,0,0,0-2.6-3.56c-3.29-3.4-5.38-3.27-8.49-1.24L22.52,73,14.9,63.33Z"
            />
        </svg>
    </Icon>
);
