import React from 'react';
import { IIcon } from '../interfaces';
import { Icon } from '../Icon';
import { IconType } from '../enums';

export const YoutubeIcon: React.FC<IIcon> = (props) => (
    <Icon className={props.className} icon={IconType.YOUTUBE}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
            <path
                fill="white"
                d="M95.55,31.44c18.88.74,37.77,1.41,56.65,2.26a101,101,0,0,1,13.3,1.59C176.15,37.2,183,44.42,185,55.66c2.77,15.41,3.33,31,2.8,46.58-.36,10.89-1.41,21.77-2.47,32.63-.77,7.95-4,14.72-11,19.24-4.28,2.76-9.11,3.56-14,4.07a519.46,519.46,0,0,1-57,2.37c-19.28-.1-38.56-.68-57.83-1.3A163.94,163.94,0,0,1,27,157.36c-10.5-1.54-17.65-8.75-19.82-19.58S4.48,116,4.16,105A278.63,278.63,0,0,1,6,61.8a77.8,77.8,0,0,1,1.77-9.59c2.49-9.63,10.36-16.12,20.79-17.42,18-2.26,36-2.45,54.11-2.75C87,32,91.25,32,95.53,32ZM77.8,68.79v55l47.75-27.51Z"
            />
        </svg>
    </Icon>
);
