import React from 'react';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { HtmlMarkdownContent } from 'components/HtmlMarkdownContent';
import { ButtonType } from 'enums/ButtonType';
import { isDomAvailable } from 'utils/isDomAvailable';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import classes from './CookiesConsent.module.scss';

interface IProps {
    data: {
        consentMessage: string;
        acceptButtonText: string;
        declineButtonText: string;
    };
}

const gdprCookie = 'gatsby-gdpr-google-analytics';

export const CookiesConsent: React.FC<IProps> = (props) => {
    const [hidden, setHidden] = React.useState(false);
    const location = useLocation();
    const onAccept = (): void => {
        document.cookie = `${gdprCookie}=true`;
        initializeAndTrack(location);
        setHidden(true);
    };
    const onDecline = (): void => {
        document.cookie = `${gdprCookie}=false`;
        setHidden(true);
    };

    if (!isDomAvailable() || (document.cookie.indexOf(gdprCookie) !== -1 && !hidden)) {
        return null;
    }

    return (
        <div
            className={classNames(classes.container, {
                [classes.hidden]: hidden,
            })}
        >
            <div className={classes.consentMessageBody}>
                <HtmlMarkdownContent content={props.data.consentMessage} withSpacing={false} />
                <div className={classes.buttonsContainer}>
                    <Button buttonType="button" type={ButtonType.SECONDARY} onClick={onAccept}>
                        {props.data.acceptButtonText}
                    </Button>
                    <Button
                        buttonType="button"
                        type={ButtonType.DEFAULT_DARK}
                        className={classes.decline}
                        onClick={onDecline}
                    >
                        {props.data.declineButtonText}
                    </Button>
                </div>
            </div>
        </div>
    );
};
