import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import classes from './HtmlMarkdownContent.module.scss';

interface IProps {
    content: string;
    className?: string;
    withSpacing?: boolean;
    style?: CSSProperties;
}

const defaultProps: Partial<IProps> = {
    withSpacing: true,
};

export const HtmlMarkdownContent: React.FC<IProps> = ({ style, className, withSpacing, content }) => (
    <div
        style={style}
        className={classNames(classes.container, classes.htmlMarkdown, {
            [classes[className as string]]: className,
            [classes.withSpacing]: withSpacing,
        })}
        dangerouslySetInnerHTML={{ __html: content }}
    />
);

HtmlMarkdownContent.defaultProps = defaultProps;
