import React from 'react';
import { IIcon } from '../interfaces';
import { Icon } from '../Icon';
import { IconType } from '../enums';

export const SkypeIcon: React.FC<IIcon> = (props) => (
    <Icon className={props.className} icon={IconType.SKYPE}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
            <path
                fill="white"
                d="M175.83,115.81a5.54,5.54,0,0,1-.46-3.55,78.67,78.67,0,0,0,.41-28.58c-6.94-42.18-46.69-71.75-88.91-66a10.53,10.53,0,0,1-6.75-1.24A46.27,46.27,0,0,0,17.21,78.23,8.05,8.05,0,0,1,18,83.9a81.6,81.6,0,0,0-1.16,13.24c.14,2.54.19,5.31.47,8a79.92,79.92,0,0,0,92.24,70.65,5.92,5.92,0,0,1,3.81.64,44,44,0,0,0,27.19,3.92C171.07,175.53,188.05,144.2,175.83,115.81Zm-39.74,21.31a38.68,38.68,0,0,1-18.88,11.66,72,72,0,0,1-41.27,0C63.48,145,54.31,137.3,50.66,124.37c-2.59-9.16,3.32-16,12.45-14.93,4,.46,6.54,2.85,8.39,6.14,1.16,2,2.12,4.23,3.16,6.36,3.39,6.95,8.85,10.89,16.62,11.73,6.35.71,12.49.26,18.37-2.41a17,17,0,0,0,5.92-4.34,10.9,10.9,0,0,0-1.79-16.26c-5.22-3.86-11.47-4.89-17.56-6.25-8.71-2-17.44-3.79-25.63-7.44C62.12,93.2,55.24,87.72,53,78.14c-2.58-11.11.5-20.5,9.4-27.71,7.85-6.35,17.28-8.41,27.05-9.25,2.26-.2,4.55,0,6.83,0,9-.24,17.81,1,26,5.11,6.24,3.18,11.4,7.52,14.41,14a14.4,14.4,0,0,1,1.21,10,10.37,10.37,0,0,1-8.21,7.86c-4.44.91-8.57.2-11.35-3.92-1.33-2-2.62-4-3.81-6-3.81-6.49-9.24-10.11-17-10.09-3.52,0-7-.1-10.48.73a18.35,18.35,0,0,0-8.28,4.23c-4.19,3.89-4,9.23.44,12.78a26.16,26.16,0,0,0,10,4.58c8.46,2.15,17,4.15,25.38,6.36a66.94,66.94,0,0,1,13.25,5.14C147.68,101.85,146.27,125.54,136.09,137.12Z"
            />
        </svg>
    </Icon>
);
