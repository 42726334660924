import React from 'react';
import { SocialType } from 'enums/SocialType';
import { ISocialLink } from 'interfaces';
import {
    LinkIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    YoutubeIcon,
    VimeoIcon,
    SkypeIcon,
    IIcon,
} from 'components/Icon';
import classes from './SocialLink.module.scss';

interface IProps {
    link: ISocialLink;
}

const getSocialIcon = (type: SocialType): React.FC<IIcon> => {
    switch (type) {
        case SocialType.FACEBOOK:
            return FacebookIcon;
        case SocialType.INSTAGRAM:
            return InstagramIcon;
        case SocialType.TWITTER:
            return TwitterIcon;
        case SocialType.LINKEDIN:
            return LinkedinIcon;
        case SocialType.YOUTUBE:
            return YoutubeIcon;
        case SocialType.VIMEO:
            return VimeoIcon;
        case SocialType.SKYPE:
            return SkypeIcon;
        default:
            return LinkIcon;
    }
};

export const SocialLink: React.FC<IProps> = (props) => {
    const IconComponent = getSocialIcon(props.link.type);

    return (
        <a href={props.link.url} target="_blank" rel="noreferrer" className={classes.link} title={props.link.type}>
            <IconComponent className={classes.icon} />
        </a>
    );
};
